var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"c047b579b5bbec94213adb38b68666b764e88de7"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

import { captureConsoleIntegration } from '@sentry/integrations'
import * as Sentry from '@sentry/nextjs'

const isProd = process.env.NEXT_PUBLIC_VERCEL_ENV === 'production'
const isPreview = process.env.NEXT_PUBLIC_VERCEL_ENV === 'preview'
const isStagingBranch = process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_REF === 'staging'
const isSentryEnabled = isProd || (isPreview && isStagingBranch)

Sentry.init({
  dsn: isSentryEnabled ? process.env.NEXT_PUBLIC_SENTRY_DSN : '',
  integrations: [
    Sentry.replayIntegration(),
    captureConsoleIntegration({
      levels: ['error', 'warn'],
    }),
  ],
  tracesSampleRate: 1.0,
  replaysSessionSampleRate: 0.01,
  replaysOnErrorSampleRate: 1.0,
})
